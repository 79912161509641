<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2952 10.6285L10.6286 13.2952C10.2814 13.6423 9.71862 13.6423 9.37149 13.2952L6.70482 10.6285C6.4506 10.3743 6.37455 9.99199 6.51213 9.65984C6.64972 9.32768 6.97384 9.11111 7.33336 9.11111H9.11114V2.88889C9.11114 2.39797 9.50911 2 10 2C10.4909 2 10.8889 2.39797 10.8889 2.88889V9.11111H12.6667C13.0262 9.11111 13.3503 9.32768 13.4879 9.65984C13.6255 9.99199 13.5495 10.3743 13.2952 10.6285ZM3.77778 11.7778V12.8444C3.77778 13.6058 3.77847 14.1234 3.81116 14.5235C3.843 14.9132 3.90071 15.1125 3.97155 15.2515C4.14199 15.586 4.41395 15.858 4.74846 16.0285C4.88748 16.0993 5.08678 16.157 5.47648 16.1888C5.87656 16.2215 6.39415 16.2222 7.15556 16.2222H12.8444C13.6058 16.2222 14.1234 16.2215 14.5235 16.1888C14.9132 16.157 15.1125 16.0993 15.2515 16.0285C15.5861 15.858 15.858 15.586 16.0285 15.2515C16.0993 15.1125 16.157 14.9132 16.1888 14.5235C16.2215 14.1234 16.2222 13.6058 16.2222 12.8444V11.7778C16.2222 11.2869 16.6202 10.8889 17.1111 10.8889C17.602 10.8889 18 11.2869 18 11.7778V12.8812C18 13.5967 18 14.1872 17.9607 14.6683C17.9199 15.1679 17.8323 15.6272 17.6125 16.0586C17.2716 16.7277 16.7277 17.2716 16.0586 17.6125C15.6272 17.8323 15.1679 17.9199 14.6683 17.9607C14.1872 18 13.5967 18 12.8812 18H7.11885C6.40332 18 5.81277 18 5.33171 17.9607C4.83207 17.9199 4.37278 17.8323 3.94137 17.6125C3.27235 17.2716 2.72842 16.7277 2.38753 16.0586C2.16772 15.6272 2.08011 15.1679 2.03928 14.6683C1.99998 14.1872 1.99999 13.5967 2 12.8812V12.8812V12.8444V11.7778C2 11.2869 2.39797 10.8889 2.88889 10.8889C3.37981 10.8889 3.77778 11.2869 3.77778 11.7778Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'UploadV2Icon',
}
</script>
