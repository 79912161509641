<template>
  <b-card no-body>
    <div>
      <div class="dashboard-title pt-2 pl-2 pr-2 d-flex justify-content-between align-items-center">
        <div>Wishlists</div>
        <div>
          <b-button
            variant="outline-primary"
            @click="goToWishlists"
          >
            Go to Wishlists
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="isLoaded"
    >
      <div
        v-if="wishlists.length"
        class="pt-2 pl-2 pr-2"
      >
        <section class="wishlist-cards dashboard-wishlist-cards">
          <wishlist-card
            v-for="wishlist in wishlists"
            :key="wishlist._id"
            class="mb-2"
            :wishlist="wishlist"
            :is-dashboard-page="true"
          />
        </section>
      </div>
      <div
        v-else
        class="mx-2 mb-2"
      >
        <div class="empty-icon-bg mb-1 ">
          <feather-icon
            icon="SearchIcon"
            size="22"
          />
        </div>
        <b-card-text class="dashboard-empty-text">
          Discover your favourite brands and<br> create beautiful custom linesheets.
        </b-card-text>
        <div class="d-flex">
          <div id="explore_button_wishlist">
            <b-button
              :to="{ name: 'explore'}"
              variant="custom-secondary"
              :disabled="!isEnabledMarketplace"
            >
              Explore
            </b-button>
          </div>
        </div>
        <b-tooltip
          v-if="!isEnabledMarketplace"
          target="explore_button_wishlist"
          placement="bottom"
        >
          {{ marketplaceAccessDeniedMessage }}
        </b-tooltip>
      </div>
    </div>
    <div
      v-else
      class="elements-center my-4"
    >
      <b-spinner />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BTooltip, BSpinner,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapState } from 'vuex'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import constants, { marketplaceAccessDeniedMessage } from '@/constants'
import { FETCH_WISHLISTS } from '@/store/modules/shop.module'
import { apiToastWarning } from '@/@core/utils/toast'
import { sortByUpdatedAt } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import WishlistCard from '../../wishlist/WishlistCard.vue'

const { BRAND_ACCESS } = c

export default {
  name: 'DashboardWishlist',
  components: {
    BCard,
    BCardText,
    BButton,
    FeatherIcon,
    BTooltip,
    BSpinner,
    WishlistCard,
  },
  data() {
    return {
      headings: [{ key: 'name', label: 'Name of list' }, { key: 'count', label: 'Items' }],
      marketplaceAccessDeniedMessage,
      wishlists: [],
      isLoaded: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
  },
  mounted() {
    this.fetchWishlist()
  },
  methods: {
    navigateToWishlist({ _id = '' }) {
      this.$router.push({
        name: 'wishlist-products',
        params: { wishlistId: _id },
      })
    },
    fetchWishlist() {
      this.$store.dispatch(FETCH_WISHLISTS, { force: true })
        .then(wishlists => {
          const sortedWishlists = sortByUpdatedAt(wishlists)
          this.wishlists = sortedWishlists?.splice(0, 4) || []
        })
        .catch(err => {
          apiToastWarning(err)
        })
        .finally(() => {
          this.isLoaded = true
        })
    },
    goToWishlists() {
      this.$router.push({ name: 'wishlist' })
      analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_GO_TO_WISHLISTS_BTN_ON_DASHBOARD, this.profile)
    },
  },
}
</script>

<style lang="scss">
.dashboard-wishlist-cards {
  .wishlist-card {
    border-radius: 5px;
    .wishlist-image {
      border-radius: 5px 5px 0 0;
    }
  }
}
</style>
