<template>
  <b-card no-body>
    <div>
      <div class="dashboard-title p-2">
        Collections
      </div>
    </div>
    <div
      v-if="isLoaded"
      class="table-responsive"
    >
      <b-table
        v-if="collections.length"
        :items="collections"
        :fields="headings"
      >
        <template #head(actions)>
          <div class="elements-center">
            actions
          </div>

        </template>
        <template #cell(actions)="data">
          <b-link
            class="elements-center"
            :to="{ name: 'collection/edit', params: { id: data.item._id } }"
          >
            <pencil-icon-2 />
          </b-link>
        </template>
      </b-table>
      <div
        v-else
        class="p-2"
      >
        <div class="empty-icon-bg mb-1 ">
          <upload-v-2-icon />
        </div>
        <b-card-text class="dashboard-empty-text">
          Place your collections on the platform.
        </b-card-text>
        <b-button
          :to="{ name: 'collection/edit'}"
          variant="custom-secondary"
        >
          Upload
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="elements-center my-4"
    >
      <b-spinner />
    </div>
  </b-card>
</template>

<script>
import {
 BTable, BCard, BLink, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import UploadV2Icon from '@/@core/assets/svg-components/UploadV2Icon.vue'
import PencilIcon2 from '@/@core/assets/svg-components/PencilIcon2.vue'
import { GET_COLLECTION_LIST } from '@/store/modules/collection.module'
import { apiToastWarning } from '@/@core/utils/toast'

export default {
  components: {
    BTable,
    BCard,
    BLink,
    BCardText,
    BButton,
    UploadV2Icon,
    PencilIcon2,
    BSpinner,
  },
  data() {
    return {
      headings: [{ key: 'name', label: 'Collection’s name' }, 'actions'],
      isLoaded: false,
      collections: [],
    }
  },
  mounted() {
    this.getCollections()
  },
  methods: {
    getCollections() {
      this.$store.dispatch(GET_COLLECTION_LIST, { limit: 5 })
      .then(res => {
        this.collections = res.data.data.collections
      })
      .catch(err => {
        apiToastWarning(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
  },
}
</script>

<style></style>
