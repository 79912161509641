<template>
  <b-card no-body>
    <div class="dashboard-title p-2 d-flex justify-content-between align-items-center">
      <div>Orders</div>
      <div>
        <b-button
          v-if="isRetailer"
          variant="outline-primary"
          @click="goToOrders"
        >
          Go to Orders
        </b-button>
      </div>
    </div>
    <template v-if="isLoaded">
      <div class="dashboard-orders-list">
        <order-list
          v-if="orders.length"
          :order-list="orders"
        />
        <div
          v-else
          class="mx-2 mb-2 pb-1"
        >
          <template v-if="isBrand">
            <div class="empty-icon-bg mb-1 ">
              <message-icon />
            </div>
            <b-card-text class="dashboard-empty-text">
              You don't have any orders yet.
            </b-card-text>
          </template>
          <template v-else>
            <div class="empty-icon-bg mb-1 ">
              <feather-icon
                icon="SearchIcon"
                size="22"
              />
            </div>
            <b-card-text class="dashboard-empty-text">
              You don't have any orders yet.
              <br>
              Explore through hundreds of brands and <br> place your first order today!
            </b-card-text>
            <div class="d-flex">
              <div id="explore_button_orders">
                <b-button
                  :to="{ name: 'explore' }"
                  variant="custom-secondary"
                  :disabled="!isEnabledMarketplace"
                >
                  Explore
                </b-button>
              </div>
            </div>
            <b-tooltip
              v-if="!isEnabledMarketplace"
              target="explore_button_orders"
              placement="bottom"
            >
              {{ marketplaceAccessDeniedMessage }}
            </b-tooltip>
          </template>
        </div>
      </div>

    </template>
    <div
      v-else
      class="elements-center my-4"
    >
      <b-spinner />
    </div>
  </b-card>
</template>

<script>
import constants, { marketplaceAccessDeniedMessage } from '@/constants'
import { mapState } from 'vuex'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
 BButton, BCard, BCardText, BSpinner, BTooltip,
} from 'bootstrap-vue'
import { GET_ORDER_LIST } from '@/store/modules/order.module'
import analytics from '@/@core/utils/analytics'
import { apiToastWarning } from '@/@core/utils/toast'
import MessageIcon from '@/@core/assets/svg-components/MessageIcon.vue'
import OrderList from '../order/OrderList.vue'
import UserRoleMixin from '../UserRoleMixin.vue'

const { BRAND_ACCESS } = c

export default {
    name: 'DashboardOrdersList',
    components: {
        BButton,
        BCard,
        BCardText,
        BTooltip,
        OrderList,
        BSpinner,
        MessageIcon,
    },
    mixins: [UserRoleMixin],
    data() {
        return {
            marketplaceAccessDeniedMessage,
            isLoaded: false,
            orders: [],
        }
    },
    computed: {
        ...mapState({
            profile: state => state.auth.profileData,
        }),
        isEnabledMarketplace() {
            return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
        },
    },
    created() {
      this.getOrders()
    },
    methods: {
      async getOrders() {
      this.$store.dispatch(GET_ORDER_LIST, { limit: 5 })
      .then(res => {
        this.orders = res.data.data.orders
      })
      .catch(err => {
        apiToastWarning(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    goToOrders() {
      this.$router.push({ name: 'orders' })
      analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_GO_TO_ORDER_BTN_ON_DASHBOARD, this.profile)
    },
  },
}
</script>

<style lang="scss">
.dashboard-orders-list {
    .card {
        border-radius: 0;
    }
}
</style>
