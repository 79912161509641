<template>
  <b-card
    no-body
    class="p-2 welcome-banner m-0 font-inter"
  >
    <div class="h-100 d-flex flex-column">
      <div class="d-flex align-items-center logo-text">
        <b-img-lazy
          v-if="profileData.logo"
          :src="profileData.logo"
          :alt="profileData.entityName"
          class="welocome-user-logo border rounded-circle"
        />
        <kingpin-logo
          v-else
          class="welocome-user-logo border rounded-circle p-5-px"
        />
        <div class="ml-5-px text-capitalize">
          {{ profileData.entityName }}
        </div>
      </div>
      <div class="welcome-text mt-auto">
        <div>Welcome to Kingpin,</div>
        <div class="text-capitalize">
          {{ profileName }}
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImgLazy } from 'bootstrap-vue'
import KingpinLogo from '@core/layouts/components/Logo.vue'

export default {
  name: 'DashboardWelcomeCard',
  components: {
    BCard,
    BImgLazy,
KingpinLogo,
  },
  props: {
    currentAssociation: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    profileData() {
      return this.$store.getters.profile
    },
    profileName() {
      return this.currentAssociation?.entity?.entityName || ''
    },
  },
}
</script>

<style lang="scss" scoped>
$profile-img-resolution: 35px;

.welcome-banner {
  .logo-text {
    font-size: 1rem;
    font-weight: 600;
  }
.welcome-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.welocome-user-logo {
  width: $profile-img-resolution;
  height: $profile-img-resolution;
}
}
</style>
