<template>
  <b-card no-body>
    <div>
      <div class="dashboard-title pt-2 pl-2 pr-2">
        <div>Brands</div>
      </div>
    </div>
    <div v-if="isLoadedAllBrands">
      <div
        v-if="brands.length"
        class="pt-2 pl-2 pr-2 wishlist-cards dashboard-brands-list"
      >
        <div
          v-for="(brand, key) in brands"
          :key="`${brand._id}-${key}`"
        >
          <b-card
            no-body
            class="wishlist-card"
            @click="goToBrandDetail(brand)"
          >
            <div>
              <b-link>

                <div class="image">
                  <b-img-lazy
                    v-if="brand.logo"
                    :src="brand.logo"
                    :alt="brand.name"
                    class="wishlist-image"
                  />
                  <kingpin-logo
                    v-else
                    class="wishlist-image p-1"
                  />
                  <div class="overlay">
                    <div class="text text-capitalize font-inter">
                      {{ brand.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </div>
          </b-card>
        </div>
      </div>
      <div
        v-else
        class="m-2"
      >
        <div class="empty-icon-bg mb-1 ">
          <feather-icon
            icon="SearchIcon"
            size="22"
          />
        </div>
        <b-card-text class="dashboard-empty-text">
          There are no brands that have invited you.
        </b-card-text>
        <div class="d-flex">
          <div>
            <b-button
              :to="{ name: 'connections' }"
              variant="custom-secondary"
            >
              Go to My Brands
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="elements-center my-4"
    >
      <b-spinner />
    </div>
  </b-card>
</template>

<script>
import {
    BCard, BCardText, BButton, BSpinner, BLink, BImgLazy,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapState } from 'vuex'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import KingpinLogo from '@core/layouts/components/Logo.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { GET_COMMON_AUTH } from '@/store/modules/common.module'
import { apiToastError } from '@/@core/utils/toast'
import UserRoleMixinVue from '../../UserRoleMixin.vue'

const { BRAND_ACCESS } = c

export default {
    name: 'DashboardWishlist',
    components: {
        BCard,
        BCardText,
        BButton,
        FeatherIcon,
        BSpinner,
        BImgLazy,
        BLink,
        KingpinLogo,
    },
    mixins: [UserRoleMixinVue],
    data() {
      return {
        directBrands: [],
        marketplaceBrands: [],
        isLoadedAllBrands: false,
      }
    },
    computed: {
        ...mapState({
            profile: state => state.auth.profileData,
        }),
        isEnabledMarketplace() {
            return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
        },
        brands() {
          const { DIRECT } = BRAND_ACCESS
          const access = this.profile?.access || []
          if (!access.length) {
            return []
          }
          const isRetailerDirectOnly = access.length === 1 && access.includes(DIRECT)
          const getBrandList = () => (isRetailerDirectOnly ? this.directBrands : [...this.directBrands, ...this.marketplaceBrands])
          const brandList = getBrandList()
          return brandList.slice(0, 4)
        },
    },
    mounted() {
      this.loadBrands()
    },
    methods: {
      async loadBrands() {
        this.isLoadedAllBrands = false
        if (this.isRetailer) {
          await this.$store.dispatch(GET_COMMON_AUTH).then(res => {
            const resData = res?.data?.data
            this.directBrands = resData?.DIRECT_BRANDS || []
            this.marketplaceBrands = resData?.BRANDS.filter(brand => brand?.access.includes(BRAND_ACCESS.MARKETPLACE))
          })
          .catch(err => {
            this.directBrands = []
            this.marketplaceBrands = []
            apiToastError(err)
          })
          this.isLoadedAllBrands = true
        }
      },
        goToBrandDetail(brand) {
            this.$router.push({
                name: 'brand/detail',
                params: {
                    brandId: brand._id,
                },
            })
            analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_A_BRAND_ON_DASHBOARD, { brand, profile: this.profile })
        },
    },
}
</script>

<style lang="scss">
@import '@/@core/scss/base/bootstrap-extended/_variables.scss';

.dashboard-brands-list {
  .wishlist-card {
  .image {

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  transition: .5s ease;
  background-color: $black;

  .text {
  display: none;
  color: $white;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
}
  }

  }
  .wishlist-card:hover .overlay {
  opacity: 0.6;

  .text {
    display: block;
  }
}
}
</style>
