<template>
  <div>
    <template>
      <b-row>
        <b-col class="col-12 col-xxl-4 col-lg-5 col-md-6">
          <div class="mb-2">
            <dashboard-referral-banner :name="profileName" />
          </div>

          <on-boarding-checklist
            v-if="showChecklist"
            :is-popup="false"
          />
          <dashboard-collections
            class="mt-2"
          />
        </b-col>
        <b-col class="col-12 col-xxl-8 col-lg-7 col-md-6">
          <!-- orders -->
          <dashboard-orders-list />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

  <script>
  import OnBoardingChecklist from '@/views/apps/dashboard/OnBoardingChecklist.vue'
  import {
     BRow, BCol,
  } from 'bootstrap-vue'
  import DashboardCollections from './brand/DashboardCollections.vue'
  import DashboardReferralBanner from './DashboardReferralBanner.vue'
  import DashboardOrdersList from './DashboardOrdersList.vue'

  export default {
    components: {
      OnBoardingChecklist,
      BRow,
      BCol,
      DashboardCollections,
      DashboardReferralBanner,
      DashboardOrdersList,
    },
    props: {
        showChecklist: {
            type: Boolean,
            default: false,
        },
        currentAssociation: {
            type: Object,
            default() {
                return null
            },
        },
    },
    computed: {
      profileName() {
        return this.currentAssociation?.entity?.entityName || ''
      },
    },
  }
  </script>
