<template>
  <div v-if="currentAssociation">
    <brand-dashboard
      v-if="isBrand"
      :current-association="currentAssociation"
      :show-checklist="showChecklist"
    />
    <retailer-dashboard
      v-if="isRetailer"
      :current-association="currentAssociation"
      :show-checklist="showChecklist"
    />
  </div>
</template>

<script>
import { showOnboardChecklist } from '@/@core/utils/utils'
import { TOOLKIT_PAGE } from '@/constants'
import { SET_LOGIN_USING_LINK } from '@/store/modules/auth.module'
import store from '@/store'
import BrandDashboard from './BrandDashboard.vue'
import RetailerDashboard from './RetailerDashboard.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'

export default {
  components: {
    RetailerDashboard,
    BrandDashboard,
  },
  mixins: [UserRoleMixinVue],
  computed: {
    showChecklist() {
      return showOnboardChecklist()
    },
    currentAssociation() {
        return this.$store.getters.getCurrentAssociation
    },
  },
  async created() {
    // Check for displaying toolkit only if signed using login Link
    const isLoginUsingLink = store.getters.isLoginUsingLink
    const profile = store.getters.profile
    if (isLoginUsingLink && profile?.shouldDisplayToolKit) {
      store.commit(SET_LOGIN_USING_LINK, false)
      this.$root.$refs.appTour.startTour(TOOLKIT_PAGE.WELCOME_TO_KINGPIN)
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-wishlist.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-dashboard.scss';
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
.dashboard-heading {
  display: inline-flex;
  align-items: center;
  span {
    margin-left: 8px;
    line-height: 1;
  }
}
.dashboard-data {
  display: flex;
  flex-direction: row;
  .onboard-checklist-width {
    width: 450px;
    padding-bottom: 3rem;
  }
  .upcoming-deadlines-width {
    width: calc(100% - 450px);
    padding-bottom: 2rem;
  }
  .upcoming-deadlines-margin {
    margin-left: 2rem;
  }
}
@media (max-width: 768px) {
  .dashboard-data {
    flex-direction: column;
    .onboard-checklist-width {
      width: 100%;
    }
    .upcoming-deadlines-width {
      width: 100%;
    }
    .upcoming-deadlines-margin {
      margin-left: 0;
    }
  }
}
.invite-header {
  background: $info;
  padding: 1rem;
  color: $white;
  text-align: center;
  margin-bottom: 1rem;
}

.text-welcome {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $dark-blue;
}

.tips-modal-content {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $neutral-black;
}
</style>
