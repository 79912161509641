<template>
  <div class="retailer-dashboard">
    <template>
      <b-row>
        <b-col class="col-12 col-xxl-8 col-lg-7 col-md-6">
          <b-row class="mb-2">
            <!-- welcome -->
            <b-col class="col-12 col-lg-6 mb-lg-0 mb-2">
              <dashboard-welcome-card :current-association="currentAssociation" />
            </b-col>
            <!-- Referral -->
            <b-col class="col-12 col-lg-6">
              <dashboard-referral-banner :name="profileName" />
            </b-col>
          </b-row>
          <!-- Brands list -->
          <dashboard-brands-list />
          <!-- Wishlist -->
          <dashboard-wishlist />
          <!-- Orders -->
          <dashboard-orders-list />
        </b-col>
        <b-col class="col-12 col-xxl-4 col-lg-5 col-md-6">
          <!-- on boarding checklist -->
          <on-boarding-checklist
            v-if="showChecklist"
            :is-popup="false"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import OnBoardingChecklist from '@/views/apps/dashboard/OnBoardingChecklist.vue'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import DashboardWishlist from './retailer/DashboardWishlist.vue'
import DashboardOrdersList from './DashboardOrdersList.vue'
import DashboardBrandsList from './retailer/DashboardBrandsList.vue'
import DashboardReferralBanner from './DashboardReferralBanner.vue'
import DashboardWelcomeCard from './DashboardWelcomeCard.vue'

export default {
  components: {
    OnBoardingChecklist,
    BRow,
    BCol,
    DashboardWishlist,
    DashboardOrdersList,
    DashboardBrandsList,
    DashboardReferralBanner,
    DashboardWelcomeCard,
  },
  props: {
        showChecklist: {
            type: Boolean,
            default: false,
        },
        currentAssociation: {
            type: Object,
            default() {
                return null
            },
        },
    },
    computed: {
      profileName() {
        return this.currentAssociation?.entity?.entityName || ''
      },
    },
  }
  </script>

  <style lang="scss">
  @import '~@core/scss/base/components/include'; // Components includes

  .retailer-dashboard {
    .wishlist-cards {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-count: 4;
    }

    @include media-breakpoint-down(md) {
    .wishlist-cards {
        grid-template-columns: 1fr 1fr;
        column-count: 3;
    }
}
@include media-breakpoint-down(sm) {
    .wishlist-cards {
        grid-template-columns: 1fr 1fr;
        column-count: 2;
    }
}
@include media-breakpoint-down(xs) {
    .wishlist-cards {
        grid-template-columns: 1fr;
        column-count: 1;
    }

}
    @media screen and (min-width: 1700px) and (max-width: 1700px) {
    .wishlist-cards {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-count: 4;
    }
  }
  }
  </style>
