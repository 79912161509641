<template>
  <div class="referral-dashboard-banner">
    <div
      class="banner"
      :class="isBrand ? 'brand-banner' : ''"
    >
      <div class="invite-block">
        <div
          class="invite-text"
          :class="isRetailer ? 'ml-xl-n1' : ''"
        >
          <span v-if="isRetailer">
            Invite a brand <br>
            and earn up to
          </span>
          <template v-else>
            <span class="brand-text">Invite all your retailers and use Kingpin Direct</span>
            <br>
            <span>as your digital order management tool, <br> completely free of charge.</span>
          </template>
          <br>
          <b-button
            size="sm"
            class="mt-1"
            variant="custom-primary"
            @click="$bvModal.show('learn_more_modal')"
          >Learn
            more</b-button>
        </div>
        <div
          v-if="isRetailer"
          class="amount ml-xl-4"
        >
          5000 $
        </div>
      </div>
    </div>

    <b-modal
      id="learn_more_modal"
      size="mg"
      modal-class="modal-primary custom-model font-inter"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div class="pb-1">
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide('learn_more_modal')"
          />
        </div>
        <div class="mt-1">
          <div class="text-center text-welcome">
            Welcome, {{ name }}!
          </div>
          <div class="tips-modal-content mt-1">
            We can’t wait to show you our new feature.
            <div>
              If you have any problems, visit the
              <b-link
                class="text-info"
                :href="HELP_URL"
              >
                support center
              </b-link>
              for tips.
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column mt-1">
          <b-button
            type="submit"
            variant="info"
            @click="getStarted"
          >
            Let’s get started
          </b-button>
          <b-link
            class="text-info text-center my-1"
            @click="$bvModal.hide('learn_more_modal')"
          >
            I don’t want any help
          </b-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BLink, BModal } from 'bootstrap-vue'
import { SET_IS_TOOLKIT_FOR_WALLET_REFERRALS, SET_SHOULD_DISPLAY_TOOLKIT_POPUP } from '@/store/modules/toolkit.module'
import { HELP_URL } from '@/constants'
import UserRoleMixinVue from '../UserRoleMixin.vue'

export default {
    name: 'DashboardReferralBanner',
    components: {
        BButton,
        BModal,
BLink,
    },
    mixins: [UserRoleMixinVue],
    props: {
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
          HELP_URL: HELP_URL(),
        }
    },
    methods: {
        getStarted() {
            this.$store.commit(SET_SHOULD_DISPLAY_TOOLKIT_POPUP, true)
            this.$store.commit(SET_IS_TOOLKIT_FOR_WALLET_REFERRALS, true)
            this.$router.push({ name: 'referrals' })
        },
    },
}
</script>
